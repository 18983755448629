import PropTypes from "prop-types"
import React from "react"
import TransitionLink from "../TansitionLink"
import { Button } from "@chakra-ui/react"

const TransitionButton = props => {
  // const buttonSize = useBreakpointValue({
  //   base: "md",
  //   sm: "md",
  //   md: "md",
  //   lg: "lg",
  // })

  return (
    <>
      <TransitionLink
        className={props.TLclassName}
        to={props.link}
        exit={{
          length: 1,
        }}
        entry={{
          delay: 0,
        }}
        state={{
          direction: props.targetPageInAnimation,
          schwerpunkt: props.schwerpunkt,
        }}
      >
        <Button
          borderRadius="full"
          colorScheme={`${props.primary ? `buttonPrimary` : `buttonSecondary`}`}
          onClick={props.onClick}
          mb="8px"
          mr="8px"
          whiteSpace="normal"
          p={{ base: "10px 16px", md: "14px 24px" }}
          w={{ base: "100%", sm: "inherit" }}
          fontSize={{ base: "inherit", md: "1.1em" }}
          h="fit-content"
          textAlign="left"
          justifyContent={{ base: "normal", sm: "center" }}
          // size={buttonSize}
          boxShadow={props.hasShadow ? "md" : "none"}
        >
          {props.targetPageInAnimation === "left" && (
            <span style={{ marginLeft: "-1px", marginRight: "4px" }}>←</span>
          )}
          {props.text}
          {props.targetPageInAnimation === "right" && (
            <span style={{ marginLeft: "4px", marginRight: "-2px" }}>→</span>
          )}
          {props.targetPageInAnimation === "down" && (
            <span style={{ marginLeft: "4px", marginRight: "-2px" }}>↓</span>
          )}
          {props.targetPageInAnimation === "up" && (
            <span
              style={{
                marginLeft: "5px",
                marginRight: "-2px",
              }}
            >
              ↑
            </span>
          )}
        </Button>
      </TransitionLink>
    </>
  )
}

TransitionButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  targetPageInAnimation: PropTypes.string,
}

TransitionButton.defaultProps = {
  text: "TransitionButton",
  link: "/",
  onClick: () => {},
  primary: false,
  targetPageInAnimation: "fade",
}

export default TransitionButton
