import React from "react"
import { isMobile } from "../../tools/isMobile"

const ProjectScene = props => {
  if (props.bottomScene && isMobile()) {
    return <div>{props.children}</div>
  }
  return (
    <div id={props.id} className="w-ful p-6 min-h-screen-3/4">
      {props.children}
    </div>
  )
}

export default ProjectScene
